<template>
  <div>
    <a-modal
      v-model="visibleModalManageCuti"
      class="mod"
      :title="status.isEdit ? 'Form Ubah Cuti' : 'Form Tambah Cuti'"
      @cancel="toggleModalManageCuti"
      centered
    >
      <form-cuti
        :dataCuti="newCuti"
        @handle-change="handleChange"
      />
      <template slot="footer">
        <a-button size="large" key="back" @click="toggleModalManageCuti">
          Batal
        </a-button>
        <a-button size="large" key="submit" type="primary" :loading="loadingActionModalManageCuti" @click="handleOkModalManageCuti">
          {{status.isEdit ? 'Ubah' : 'Tambah'}} Sekarang
        </a-button>
      </template>
    </a-modal>
    <div class="d-flex align-items center">
      <div class="ml-auto">
        <a-button @click.prevent="toggleModalManageCuti('add')" size="large" type="primary"><a-icon type="plus" />Tambah Cuti</a-button>
      </div>
    </div>
    <div class="mt-4">
      <a-table
        :columns="cutiColumns"
        :data-source="cutiDataTable"
        :pagination="pagination"
        :loading="loadingTable"
        bordered
      >
        <span slot="durasi_maksimal" slot-scope="value, record"> {{value}} {{record.tipe_durasi.toUpperCase()}} </span>
        <div slot="aksi" slot-scope="value, record">
          <a-button
            @click.prevent="toggleModalManageCuti('edit', record)"
            class="mr-3 text-warning border border-warning"
            size="large"
          >
            <a-icon type="edit"/>Ubah
          </a-button>
          <a-button
            @click.prevent="handleDeleteCuti(record)"
            class="text-danger border border-danger"
            size="large"
          >
            <a-icon type="delete" />Hapus
          </a-button>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
const FormCuti = () => import('@/components/app/AdminHR/Cuti/FormCuti')
const cutiColumns = [
  {
    title: 'Nama',
    dataIndex: 'nama',
    key: 'nama',
    scopedSlots: { customRender: 'nama' },
  },
  {
    title: 'Regulasi',
    dataIndex: 'regulasi',
    key: 'regulasi',
    width: 200,
    align: 'center',
    scopedSlots: { customRender: 'regulasi' },
  },
  {
    title: 'Durasi Maksimal',
    dataIndex: 'durasi_maksimal',
    key: 'durasi_maksimal',
    width: 200,
    align: 'center',
    scopedSlots: { customRender: 'durasi_maksimal' },
  },
  {
    title: 'Aksi',
    dataIndex: 'aksi',
    key: 'aksi',
    scopedSlots: { customRender: 'aksi' },
    align: 'center',
  },
]
export default {
  components: {
    FormCuti,
  },
  data() {
    return {
      cutiColumns,
      cutiDataTable: [],
      pagination: {},
      loadingTable: false,
      visibleModalManageCuti: false,
      loadingActionModalManageCuti: false,
      status: {
        isEdit: false,
        isCreate: false,
      },
      newCuti: {
        nama: null,
        regulasi: null,
        deskripsi: null,
        tipe: null,
        durasi_maksimal: null,
        tipe_durasi: null,
      },
      idEdit: null,
    }
  },
  methods: {
    handleChange(payload) {
      const { value, column } = payload

      this.newCuti[column] = value
    },
    toggleModalManageCuti(status, data) {
      this.visibleModalManageCuti = !this.visibleModalManageCuti
      if (!this.visibleModalManageCuti) {
        setTimeout(() => {
          this.status = {
            isEdit: false,
            isCreate: false,
          }
          this.newCuti = {
            nama: null,
            regulasi: null,
            deskripsi: null,
            tipe: null,
            durasi_maksimal: null,
            tipe_durasi: null,
          }
          this.idEdit = null
        }, 500)
        return ''
      }

      if (status) {
        this.status[status === 'add' ? 'isCreate' : 'isEdit'] = true
        if (status === 'edit') {
          this.idEdit = data.key
          this.newCuti = {
            nama: data.nama,
            regulasi: data.regulasi,
            deskripsi: data.deskripsi,
            tipe: data.tipe,
            durasi_maksimal: data.durasi_maksimal,
            tipe_durasi: data.tipe_durasi,
          }
        }
      }
    },
    handleOkModalManageCuti() {
      const content = `${this.status.isEdit ? 'Apakah anda yakin ingin mengubah cuti ini? perubahan ini akan mempengaruhi sistem absensi pegawai' : 'Apakah anda yakin ingin membuat cuti baru ini? perubahan ini akan disimpan kedalam sistem'}`
      if (this.isValidateForm) {
        this.$notification.error({
          message: 'Peringatan',
          description: 'Semua kolom wajib diisi',
        })
      } else {
        this.$confirm({
          title: 'Peringatan',
          content: (
            <div>{content}</div>
          ),
          onOk: () => {
            this.loadingActionModalManageCuti = true
            const payload = this.status.isEdit ? {
              id: this.idEdit,
              dataCuti: this.newCuti,
            } : {
              dataCuti: this.newCuti,
            }
            this.$store.dispatch(`cuti/${this.status.isEdit ? 'EDIT' : 'POST'}_CUTI`, payload)
              .then(isSuccess => {
                this.loadingActionModalManageCuti = false
                this.toggleModalManageCuti()
                this.fetchDataCuti()
                if (isSuccess) {
                  const description = this.status.isEdit ? 'Cuti berhasil diubah' : 'Cuti baru berhasil ditambahkan'
                  this.$notification.success({
                    message: 'Berhasil',
                    description,
                  })
                } else {
                  const description = this.status.isEdit ? 'Cuti gagal diubah' : 'Cuti baru gagal ditambahkan'
                  this.$notification.error({
                    message: 'Gagal',
                    description,
                  })
                }
              })
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: this.status.isEdit ? 'Ubah Sekarang' : 'Tambah Sekarang',
          cancelText: 'Batal',
        })
      }
    },
    handleDeleteCuti(data) {
      this.$confirm({
        title: 'Peringatan',
        content: (
          <div>Apakah anda yakin ingin menghapus cuti <b>{data.nama}</b> ? perubahan ini akan mempengaruhi sistem absensi pegawai</div>
        ),
        onOk: () => {
          this.loadingTable = true
          this.loadingDeleteCuti = true
          this.$store.dispatch('cuti/DELETE_CUTI', {
            id: data.key,
          })
            .then(isSuccess => {
              this.fetchDataCuti()
              this.loadingDeleteCuti = false
              if (isSuccess) {
                this.$notification.success({
                  message: 'Berhasil',
                  description:
                    'Cuti berhasil dihapus',
                })
              } else {
                this.$notification.error({
                  message: 'Gagal',
                  description: 'Cuti gagal dihapus',
                })
              }
            })
        },
        onCancel: () => {
          this.loadingDeletePeriode = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Hapus',
        cancelText: 'Batal',
      })
    },
    fetchDataCuti(params = { page: 1, order: 'ASC', search: '', type: '', sortBy: '' }) {
      this.loadingTable = true
      this.$store.dispatch('cuti/FETCH_CUTI', { page: params.page, order: params.order, search: params.search, sortBy: params.sortBy })
        .then(res => {
          this.loadingTable = false
          // const pagination = { ...this.pagination }
          // pagination.total = res.total
          // this.pagination = pagination
          this.cutiDataTable = res.map(el => {
            return {
              key: el.id,
              ...el,
            }
          })
        })
    },
  },
  mounted() {
    this.fetchDataCuti()
  },
  computed: {
    isValidateForm() {
      if (this.newCuti.nama && this.newCuti.tipe && this.newCuti.regulasi && this.newCuti.durasi_maksimal && this.newCuti.tipe_durasi) {
        return false
      }
      return true
    },
  },
}
</script>

<style>

</style>
